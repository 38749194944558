import React from 'react'
import styles from './Loader.module.css';
function Loader() {
  return (
    <div className={styles.container_loader}>
    <div className={styles.circle_loader}></div>
    <div className={styles.circle_loader}></div>
    <div className={styles.circle_loader}></div>
    <div className={styles.shadow_loader}></div>
    <div className={styles.shadow_loader}></div>
    <div className={styles.shadow_loader}></div>
  </div>
  )
}

export default Loader