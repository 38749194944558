import React from "react";
import "./Inspiration.css";

const Inspiration = () => {
  return (
    <div className="container" id="inspiration">
      <h1 className="recent_heading">Our Inspiration</h1>
      <div className="row Inspiraion-img">
        <div class="col-xxl-6 col-lg-6 col-md-12 col-sm-12" id="inspiration-1">
          <div className="text-center" id="pht-1">
            <img
              src="umbarkar.png"
              class="img-fluid border border-5"
              id="img1"
              alt="Umbarkar Sir"
            />
            <p className="StaffName">Dr. A. J. Umbarkar</p>
            <p className="StaffPosition">
              HOD Information Technology Department
            </p>
            <p className="StaffPosition">And Staff Advisor</p>
          </div>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-12 col-sm-12" id="inspiration-2">
          <div className="text-center" id="pht-2">
            <img
              src="surve.png"
              class="img-fluid border border-5"
              id="img2"
              alt="Anil Surve sir"
            />
            <p className="StaffName">Dr. A. R. Surve</p>
            <p className="StaffPosition">Chairperson ECAC and</p>
            <p className="StaffPosition">Staff Advisor</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inspiration;
