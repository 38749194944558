import React, { useEffect, useState } from "react";
import classes from "./Detail.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const Detail = () => {
  const { type, id } = useParams();
  const [Data, setData] = useState({
    title: "",
    image: "",
    brief: "",
    detail: "",
    conductedBy: [],
    date: "",
    venue: "",
  });
  useEffect(() => {
    const getData = async () => {
      await await axios
        .post(
          `https://backend.wcewlug.org/api/v1/${
            type === "Event" ? "events" : "clubservices"
          }/getSingle`,
          { _id: id },
          {
            Headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (type === "Event") {
            setData({
              title: res.data.theBoys.event.name,
              brief: res.data.theBoys.event.brief,
              detail: res.data.theBoys.event.detail,
              conductedBy: res.data.theBoys.event.conductedBy,
              date: res.data.theBoys.event.conductedOn,
              venue: res.data.theBoys.event.venue,
              image: res.data.theBoys.event.photo,
            });
          } else {
            setData({
              title: res.data.theBoys.CS.title,
              brief: "",
              detail: res.data.theBoys.CS.Detail,
              conductedBy: res.data.theBoys.CS.presenterName,
              date: res.data.theBoys.CS.Date,
              venue: "",
              image: res.data.theBoys.CS.Image,
            });
          }
        });
    };
    getData();
  }, [type, id]);
  return (
    <div className={classes.Detail}>
      <div className={classes.Detail_Header}>
        <h1>{type === "Event" ? "Event" : "Club Service"}</h1>
      </div>
      <div className={classes.Detail_MainContainer}>
        <div className={classes.Detail_DetailsContainer}>
          <div className={classes.Detail_FirstSection}>
            <h2>{Data.title}</h2>
            {type === "Event" ? <p>{Data.brief}</p> : ""}
          </div>
          <div className={classes.Detail_SecondSection}>
            <p>{Data.detail}</p>
          </div>
          <div className={classes.Detail_ThirdSection}>
            <div className={classes.Detail_Item}>
              <p className={classes.Detail_Head}>Conducted By:</p>
              <p>{Data.conductedBy.map((item) => item + ", ")}</p>
            </div>
            <div className={classes.Detail_Item}>
              <p className={classes.Detail_Head}>Conducted On:</p>
              <p>{Data.date}</p>
            </div>
            <div className={classes.Detail_Item}>
              <p className={classes.Detail_Head}>Venue:</p>
              <p>{Data.venue}</p>
            </div>
          </div>
        </div>
        <div className={classes.Detail_ImageContainer}>
          <img src={Data.image} alt={type} />
        </div>
      </div>
    </div>
  );
};

export default Detail;
