import React, { useEffect, useState } from "react";
import "./Activities.css";
import axios from "axios";
import { Link } from "react-router-dom";

const Activities = () => {
  const [clubServices, setClubServices] = useState([]);

  useEffect(() => {
    const getClubServices = async () => {
      await axios
        .get("https://backend.wcewlug.org/api/v1/clubservices/getAllCS")
        .then((res) => {
          setClubServices(res.data.theBoys.clubService);
        });
    };
    getClubServices();
  }, []);
  return (
    <div class="container">
      <h1 className="recent_heading">Recent Activities</h1>
      <div className="cardContainer">
        {clubServices[0] !== undefined ? (
          <div class="card-deck">
            <div class="cardInside" id="cd-1">
              <img
                src={clubServices[0].Image}
                class="card-img-top csImage"
                alt="..."
              />
              <div class="card-Homebody">
                <h5 class="card-title">{clubServices[0].title}</h5>
                <p class="card-text">
                  {clubServices[0].Detail}
                  <br /> <span>📆Date: {clubServices[0].Date}</span>
                </p>
                <div id="btn">
                  {" "}
                  <Link
                    to={`/Detail/Clubservices/${clubServices[0]._id}`}
                    class="btn btn-success"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {clubServices[1] !== undefined ? (
          <div class="card-deck">
            <div class="cardInside" id="cd-1">
              <img
                src={clubServices[1].Image}
                class="card-img-top csImage"
                alt="..."
              />
              <div class="card-Homebody">
                <h5 class="card-title">{clubServices[1].title}</h5>
                <p class="card-text">
                  {clubServices[1].Detail}
                  <br /> <span>📆Date: {clubServices[1].Date}</span>
                </p>
                <div id="btn">
                  {" "}
                  <Link
                    to={`/Detail/Clubservices/${clubServices[1]._id}`}
                    class="btn btn-success"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {clubServices[2] !== undefined ? (
          <div class="card-deck">
            <div class="cardInside" id="cd-1">
              <img
                src={clubServices[2].Image}
                class="card-img-top csImage"
                alt="..."
              />
              <div class="card-Homebody">
                <h5 class="card-title">{clubServices[2].title}</h5>
                <p class="card-text">
                  {clubServices[2].Detail}
                  <br /> <span>📆Date: {clubServices[2].Date}</span>
                </p>
                <div id="btn">
                  {" "}
                  <Link
                    to={`/Detail/Clubservices/${clubServices[2]._id}`}
                    class="btn btn-success"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Activities;
