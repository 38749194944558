import { AnimatePresence, motion } from "framer-motion";
import "./Sidebar.css";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiAward } from "react-icons/fi";
import { HiDesktopComputer } from "react-icons/hi";
import { AiOutlineUserAdd } from "react-icons/ai";
import { RiGalleryLine } from "react-icons/ri";
import { FiMail } from "react-icons/fi";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { VscChromeClose } from "react-icons/vsc";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
const routes = [
  {
    path: "/",
    name: "Home",
    icon: <AiOutlineHome />,
  },
  {
    path: "/blogs",
    name: "Blogs",
    icon: <AiOutlineFileSearch />,
  },
  {
    path: "/*",
    name: "Activities",
    class: "par",
  },
  {
    path: "/events",
    name: "Events",
    icon: <FiAward />,
  },
  {
    path: "/club-service",
    name: "Club Services",
    icon: <HiDesktopComputer />,
  },
  {
    path: "/*",
    name: "Team",
    class: "par",
  },
  {
    path: "/members/mentor",
    name: "Members",
    icon: <AiOutlineUserAdd />,
  },
  {
    path: "/gallery",
    name: "Gallery",
    icon: <RiGalleryLine />,
  },
  {
    path: "/*",
    name: "Support",
    class: "par",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    icon: <FiMail />,
    exact: true,
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: <BiMessageRoundedDetail />,
  },
];

const showAnimation = {
  hidden: {
    width: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  showNow: {
    opacity: 1,
    width: "auto",
    transition: {
      duration: 0.5,
    },
  },
};

const Sidebar = ({ dropVal, setShow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    setShow(true);
  };

  useEffect(() => {
    setIsOpen(dropVal);
  }, [dropVal]);

  return (
    <>
      <AiOutlineMenuUnfold className="Outsider" onClick={toggle} />
      <motion.div
        onHoverStart={() => {
          setShow(true);
          setIsOpen(true);
        }}
        onHoverEnd={() => {
          setShow(false);
          setIsOpen(false);
        }}
        animate={{
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className={isOpen ? "sidebar grow" : "sidebar shrink"}
      >
        <div className="Align">
          <div className="top_section">
            {/* {isOpen && (
          <motion.h1
            variants={showAnimation}
            initial="hidden"
            animate="showNow"
            exit="hidden"
            className="logo"
          ></motion.h1>
        )} */}
            <div className={"bars sided"}>
              <AiOutlineMenuUnfold
                className="opener"
                onClick={() => {
                  setShow(true);
                  setIsOpen(true);
                }}
              />
              <VscChromeClose
                className="close"
                onClick={() => {
                  setShow(false);
                  setIsOpen(false);
                }}
              />
              {/* <AiOutlineMenuUnfold className="opener" onClick={toggle} />
              <VscChromeClose onClick={toggle} className="close" /> */}
            </div>
            {isOpen && (
              // <motion.p
              //   variants={showAnimation}
              //   initial="hidden"
              //   animate="showNow"
              //   exit="hidden"
              //   className="des"
              // >
              <div className="des">
                <img
                  src="https://res.cloudinary.com/dcglxmssd/image/upload/v1686298289/image_jjblc7.png"
                  alt="logo"
                />
                <p>Walchand Linux Users' Group</p>
              </div>

              // </motion.p>
            )}
          </div>
          <section className="routes">
            {routes.map((route) => (
              <NavLink
                activeclassname="active"
                to={route.path}
                key={route.name}
                onClick={() => {
                  setIsOpen(false);
                  setShow(false);
                }}
                className={isOpen ? "link" : "link linkClosed"}
              >
                <div className="icon">{route.icon}</div>
                <AnimatePresence>
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="showNow"
                    exit="hidden"
                    className={isOpen ? "link-text" : "link-text hide"}
                  >
                    {route.name}
                  </motion.div>
                </AnimatePresence>
              </NavLink>
            ))}
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
