import React from "react";
// import Footer from '../HomePage/Footer'
import { useState,useEffect } from "react";
import Loader from "../Loader";
import "./Blog.css";
import Blogspage from "./blogspage";
import RecentBlogs from "./RecentBlogs";
import Trending from "./Trending";
function Blogs() {
  const [myBlogs , setMyBlogs] = useState([]);
  const [loading,setLoading] = useState(true);

  const getData = async () => {
    const rawData = await fetch("https://backend.wcewlug.org/api/v1/blogs");
    const sortedData  = await rawData.json();
    setMyBlogs(sortedData);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  },[]);
  const sideBlog = myBlogs.theBoys !== undefined? myBlogs.theBoys.blog.slice(1,3):[];
  const trendingBlogs = myBlogs.theBoys !== undefined? myBlogs.theBoys.blog.slice(2,4):[];
  const soloBlog = myBlogs.theBoys !== undefined? [myBlogs.theBoys.blog[0]]:[];

  return (
    <>
    {loading ? <Loader /> : 
    
    <div className="container-fluid blogs_page">
    <Blogspage soloBlog={soloBlog} sideBlog={sideBlog} isLoading={loading}/>
    <Trending trendingBlog={trendingBlogs}/>
      <RecentBlogs recentBlog={myBlogs.theBoys !== undefined?myBlogs.theBoys.blog:[]}/>
   </div>
    
    }
   
   </>
  );
}

export default Blogs;
