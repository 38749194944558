import React, { useRef } from "react";
import "../HomePage/Contact.css";
import { BsDiscord } from "react-icons/bs";
import { TbBrandTelegram } from "react-icons/tb";
import { BsLinkedin } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import { BsGithub } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import emailjs from "emailjs-com";


const Contact = () => {
  const form = useRef();
  const first = useRef();
  const last = useRef();
  const email = useRef();
  const message = useRef();
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_g3pzx89",
        "template_hhq2cxi",
        form.current,
        "e8cV0gtTxTYPQ1fpg"
      )
      .then(
        (result) => {
          if (result.status === 200) {
            alert("We have recieved your email, Thanks for contacting us!");
            first.current.value = "";
            last.current.value = "";
            email.current.value = "";
            message.current.value = "";
          }
          console.log(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return (
    <div class="FormOuterContainer" id="contact_section">
      <div class="FormContainer">
        <form
          ref={form}
          class="col-xxl-6 col-lg-6 col-md-12 col-sm-12"
          id="left-container"
        >
          <p id="contact_head-1">
            Contact <span id="contact_head-2">Us</span>
          </p>
          <p id="contact_description">
            Any question or remarks? Just write us a message
          </p>
          <div class="row">
            <div class="col">
              <label className="sub-head labelText">First name</label>
              <input
                ref={first}
                name="user_firstname"
                className="form-control input_box_contact"
                id="fname"
                placeholder="Matthew"
              />
            </div>
            <div class="col">
              <label className="sub-head labelText">Last name</label>
              <input
                ref={last}
                name="user_lastname"
                className="form-control input_box_contact"
                id="lname"
                placeholder="Perry"
              />
            </div>
          </div>
          <br />
          <div class="mb-3">
            <p className="sub-head labelText">Mail</p>
            <input
              ref={email}
              name="user_email"
              type="email"
              class="form-control input_box_contact"
              id="mail"
              placeholder="name@example.com"
            />
          </div>
          <div class="mb-3">
            <p className="sub-head labelText">Message</p>
            <textarea
              ref={message}
              name="message"
              class="form-control"
              id="message"
              placeholder="Write your message..."
              rows="4"
            ></textarea>
          </div>
          <div class="d-grid gap-2 justify-content-md-end" id="cnt">
            <button
              onClick={sendEmail}
              class="btn"
              type="button"
              id="send_msg_btn"
            >
              Send my message
            </button>
          </div>
        </form>
        <div class="sideContainer">
          <div class="innerInfo flex align-center justify-center direction-column">
            <h3 id="head-con">Or check out these resources</h3>
            <div className="side-by-side con-1">
              <div className="left-content">
                <BsDiscord />
              </div>
              <div className="right-content">
                <h3>Join our Discord</h3>
                <p>Join community of growing linux enthusiasts</p>
              </div>
            </div>
            <div className="side-by-side con-1">
              <div className="left-content">
                <TbBrandTelegram />
              </div>
              <div className="right-content">
                <h3>Read our blogs</h3>
                <p>
                  Read blogs on interesting, fascinating and immerging
                  technologies
                </p>
              </div>
            </div>
            <div className="con-2">
              <div id="icon-1">
                <a href="https://www.linkedin.com/company/wlug-club/">
                  <BsLinkedin />
                </a>
              </div>
              <div id="icon-2">
                <a href="https://mobile.twitter.com/wcewlug">
                  <FiTwitter />
                </a>
              </div>
              <div id="icon-3">
                <a href="https://github.com/Walchand-Linux-Users-Group">
                  <BsGithub />
                </a>
              </div>
              <div id="icon-4">
                <a href="https://instagram.com/wcewlug?igshid=YmMyMTA2M2Y=">
                  <BsInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
