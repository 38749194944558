import React, { useState, useEffect } from "react";
import Eventspage from "./Eventspage";
import Pastevents from "./Pastevents";
import Loader from "../Loader";



//Same CSS is used to for Events as Blogs page and Events page are similar
function Events() {
  const [loading, setLoading] = useState(true);
  const [events, setMyBlogs] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [])

  const getData = async () => {
    const rawData = await fetch("https://backend.wcewlug.org/api/v1/events");
    const sortedData = await rawData.json();
    setMyBlogs(sortedData);
    console.log(sortedData);
  };


  useEffect(() => {
    getData();
  }, []);
  // console.log(events.theBoys);
  const sideEvent = events.theBoys !== undefined ? events.theBoys.event.slice(1, 3) : [];
  const pastEvents = events.theBoys !== undefined ? events.theBoys.event.slice(2, 4) : [];
  const soloEvent = events.theBoys !== undefined ? [events.theBoys.event[0]] : [];



  console.log("EVENTS API:", soloEvent, sideEvent, pastEvents);

  return (
    <>
      {loading ? <Loader /> :

        <div className="container-fluid blogs_page">
          <Eventspage soloEvent={soloEvent} sideEvent={sideEvent} />
          <Pastevents pastEvents={events.theBoys !== undefined ? events.theBoys.event : []} />
        </div>

      }

    </>
  )
}


export default Events;