import React from 'react'
import { Link } from "react-router-dom";
import ScrollContainer from 'react-indiana-drag-scroll';


function Pastservices({pastServices}) {
  return (
    <div className="container-fluid">
      <hr className="purp-hr" />
      <div className="recent-blogs-heading">
        <p className="recent-blogs" id="headings">
          PAST CLUB SERVICES
        </p>
      </div>
      <ScrollContainer
        horizontal="true"
        className="wrapper-container-recent-blogs container-fluid rowCSS gridGap"
      >
        {pastServices.map((curr) => {
          return (
            <div className="card blog_card" key={curr._id}>
              <div className="card-body Recentcard">
                <div className="SectionFirst">
                <img
                  src={
                    curr !== undefined
                      ? curr.Image
                      : "https://p4.wallpaperbetter.com/wallpaper/500/615/740/technology-linux-penguin-wallpaper-preview.jpg"
                  }
                  className="card-img-top solo_blog_image"
                  alt=".."
                />
                <h5 className="card-title head">
                  {curr !== undefined ? curr.title : ""}
                </h5>
                <div className="cardDescription">
                  <p className="card-text">
                    {curr !== undefined ? curr.Detail : ""}
                  </p>
                </div>
                </div>
                <Link to={`/Detail/Clubservices/${curr._id}`} className="btn btn-primary card-btn">Read More</Link>
            
              </div>
            </div>
          );
        })}
      </ScrollContainer>
    </div>
  )
}

export default Pastservices;
