import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import "./BoardList.css";
import { title, sub_title } from "../../../App";
import { useContext } from "react";
import axios from "axios";
import Dropdown from 'react-bootstrap/Dropdown'

const currentMentor=2024;

const BoardList = () => {
  const [currentMentorYear,setCurrentMentorYear]=useState(currentMentor);
  const [Mentor, setMentor] = useState([]);
  const [Main,setMain]=useState([]);
  const [Assitant,setAssitant]=useState([]);
  const [Member,setMember]=useState([]);
  const title21 = useContext(title);
  const sub_title21 = useContext(sub_title);

  const compare=(a,b)=>{
    return a.priority>b.priority?1:-1
  }

  useEffect(() => {
    const getUsers = async () => {
      await axios
        .post("https://backend.wcewlug.org/api/v1/getAll/",{
          "currentMentor":currentMentor
      },{
        headers:{"Content-Type":"application/json"}
      })
        .then((res) => {
          setMentor(res.data.theBoys.users.filter(item=>item.boardOf===currentMentor).sort(compare));
          setMain(res.data.theBoys.users.filter(item=>item.boardOf===currentMentor+1).sort(compare));
          setAssitant(res.data.theBoys.users.filter(item=>item.boardOf===currentMentor+2).sort(compare));
          setMember(res.data.theBoys.users.filter(item=>item.boardOf===currentMentor+3).sort(compare));
        });
    };
    getUsers();
  }, []);
  // https://wlug-backend.onrender.com
  useEffect(()=>{
    const getMentors=async()=>{
      await axios.post("https://backend.wcewlug.org/api/v1/getAll/getSpecific/",{boardOf:currentMentorYear},{
        headers:{
          "Content-Type":"application/json"
        }
      }).then(res=>{
        setMentor(res.data.theBoys.users.sort(compare))
      })
    }
    getMentors();
  },[currentMentorYear])

  return (
    <>
            {title21.split(" ")[0]==="Mentor"?<div className="ContainerSelectYr">
        <Dropdown style={{"backgroundColor":"#273239"}} onSelect={(eve)=>setCurrentMentorYear(eve)}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Select Year
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                
                <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
                <Dropdown.Item eventKey="2021">2021</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </div>:""}
      <center>
        <div className="list_title">{title21}</div>
      </center>

      <div className="note-list">
        {title21.split(" ")[0]==="Mentor"? Mentor.map((item) => {
            return <Card
              key={item._id}
              name={item.name}
              image={item.photo}
              quote={item.quote}
              areaOfInterest={item.areaOfInterest}
              // role={item.role}
              board={item.board}
              mail={item.email}
              insta={item.instagram}
              linkedin={item.linkedin}
            />;
        }):""}
        {title21.split(" ")[0]==="Main"? Main.map((item) => {
            return <Card
              key={item._id}
              name={item.name}
              image={item.photo}
              quote={item.quote}
              areaOfInterest={item.areaOfInterest}
              role={item.role}
              board={item.board}
              mail={item.email}
              insta={item.instagram}
              linkedin={item.linkedin}
            />;
        }):""}
        {title21.split(" ")[0]==="Assistant"? Assitant.map((item) => {
            return <Card
              key={item._id}
              name={item.name}
              image={item.photo}
              quote={item.quote}
              areaOfInterest={item.areaOfInterest}
              role={item.role}
              board={item.board}
              mail={item.email}
              insta={item.instagram}
              linkedin={item.linkedin}
            />;
        }):""}
        {title21.split(" ")[0]==="Member"? Member.map((item) => {
            return <Card
              key={item._id}
              name={item.name}
              image={item.photo}
              quote={item.quote}
              areaOfInterest={item.areaOfInterest}
              role={"Member"}
              board={item.board}
              mail={item.email}
              insta={item.instagram}
              linkedin={item.linkedin}
            />;
        }):""}
      </div>
    </>
  );
};

export default BoardList;
