import React from "react";
import classes from "./About.module.css";

const About = () => {
  return (
    <div className={classes.About_Main}>
      <h1>ABOUT US</h1>
      <div className={classes.About_ImgContainer}>
        <img
          src="https://res.cloudinary.com/dcglxmssd/image/upload/v1681894222/IMG_20230129_190150_luhb4x.jpg"
          alt="poster"
        />
      </div>
      <p>
        We are a Club of Linux and Open Source Enthusiasts established in 2003
        from Walchand College of Engineering, Sangli who actively takes part in
        training students with limited knowledge of Open Source to fully fledged
        Open Source developers. We conduct various activities throughout the
        year to promote Open Source technologies including talks, discussions,
        bootcamps, and competitions.
      </p>
    </div>
  );
};

export default About;
