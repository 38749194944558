import React, { useState } from "react";
import "./Login.css";
// import { WavyLink } from 'react-wavy-transitions';
import { useFormik } from "formik";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { FiKey } from "react-icons/fi";
import { FaRocket } from "react-icons/fa";
import axios from "axios";
import * as Yup from "yup";
import Loader from "../Loader";

function Login(props) {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Username is required*"),
      password: Yup.string()
        .min(8, "minimum 8 required")
        .required("Password is required*"),
    }),
    onSubmit: (values) => {
      console.log(values);
      LoginHandler(values);
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const LoginHandler = (values) => {
    const URL = "https://backend.wcewlug.org/api/v1/users/login";
    setIsLoading(true);
    axios
      .post(
        URL,
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          sessionStorage.setItem("email", values.email);
          sessionStorage.setItem("token", data["token"]);
          console.log(data["token"]);
          console.log(response.status);
          console.log(sessionStorage.getItem("email"));
          setIsLoading(false);
          alert("Successfully Logged In");
          window.location = "/";
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 400") {
          // setAlertCode(1);
          alert("Bad Request");
          setIsLoading(false);
          return 0;
        }
        if (err.message === "Request failed with status code 404") {
          // setAlertCode(2);
          alert("You have entered an invalid username or password");
          setIsLoading(false);

          return 0;
        }
        if (err.message === "Request failed with status code 401") {
          // setAlertCode(3);
          alert("You have entered an invalid username or password");
          setIsLoading(false);
          return 0;
        }
        return 0;
      });
    values.email = "";
    values.password = "";
  };
  const websiteButton = () => {
    window.location.href = "/";
  };

  const Login =(
    <>
    <div className="container-fluid login">
        <div className="arrow_back">
          <AiOutlineArrowLeft onClick={websiteButton} />
        </div>
        <div className="Login_Container">
        <div className="login_form">
          <form className="Main_Login">
            <div className="heading">
              <h1>WLUG</h1>
            </div>
            <div className="mainForm">
              <div className="input_form">
                <FiUser className="logo" />
                <input
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="off"
                      placeholder="Your username or e-mail"
                      className="input"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      />
              </div>
              <div className="input_form">
                <FiKey className="logo" />
                <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="input"
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
                <p className="error_login">{formik.errors.password}</p>
              ) : null}
              <div className="lower_login">
                <div className="forgot">
                  <p onClick={websiteButton}>Forgot Password?</p>
                </div>

                <div className="login_btn" type="submit">
                  <button className="btn" >
                    <p className="btn_txt">Log in</p>
                  </button>
                </div>
              </div>
          </form>
        </div>
        <div className="login_card ">
          <div className="card-body_login">
            <div className="Avatar">
            </div>
            <div className="welcome ">
              <p>Welcome!</p>
            </div>
            <div className="subtext">
              <p>Linux Enthusiasts!!</p>
            </div>

            <div className="website_btn" onClick={websiteButton}>
              {/* <button className="" > */}
                <p>
                  To Website <FaRocket className="rocket" />
                </p>
              {/* </button> */}
            </div>

          </div>
        </div>
        </div>
      </div>

      
      </>
  );



  return (
    <div>
      {isLoading?<Loader/>:Login}   
    </div>
  );
}

export default Login;
