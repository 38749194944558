/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Card from "react-bootstrap/Card";
import "./Gallery.css";

function Gallery() {
  return (
    <>
      <p className="trending-posts" id="headings">
        Gallery
      </p>
      <div className="container gallery">
        <Card style={{ width: "18rem" }} className="cardCustom">
          <Card.Img
            variant="top"
            className="img-gallery"
            src="https://res.cloudinary.com/dcglxmssd/image/upload/v1686725953/IMG_20230429_122656_586_wmmdgj.jpg"
          />

          <div className="text">
            {" "}
            <a
              href="https://photos.app.goo.gl/jT2sQnLWDoQ7MF2Y8"
              className="galleryImage"
              rel="noreferrer"
              target="_blank"
            >
              TechnoTweet
            </a>
          </div>
        </Card>
        <Card style={{ width: "18rem" }} className="cardCustom">
          <Card.Img
            variant="top"
            className="img-gallery"
            src="https://res.cloudinary.com/dcglxmssd/image/upload/v1681896486/WhatsApp_Image_2023-04-17_at_3.13.29_PM_xvd0tg.jpg"
          />

          <div className="text">
            {" "}
            <a
              href="https://photos.app.goo.gl/QybaZDcaVTa7DBWb7"
              className="galleryImage"
              rel="noreferrer"
              target="_blank"
            >
              Metamorphosis
            </a>
          </div>
        </Card>

        <Card style={{ width: "18rem" }} className="cardCustom">
          <Card.Img
            variant="top"
            className="img-gallery"
            src="https://lh3.googleusercontent.com/pw/AJFCJaVBfxGUQ0P1C3kpYBf7CDy3tRFK0WnovgdM96y8VP6MtLPkNM1ux-JCeuhOKHFG9XZj-Sq6MmSAbj72Os6m0ic4OfRL7VuXTC21xT71REjdFO8FDEgjTTxzMZiaCJMw_PkEqOU3YXXBCAE51ZVt7OWQ=w711-h948-s-no?authuser=0"
          />

          <div className="text">
            {" "}
            <a
              href="https://photos.app.goo.gl/kSY4vXxCCdRPpQgYA"
              className="galleryImage"
              rel="noreferrer"
              target="_blank"
            >
              Linux Diary
            </a>
          </div>
        </Card>

        <Card style={{ width: "18rem" }} className="cardCustom">
          <Card.Img
            variant="top"
            className="img-gallery"
            src="https://lh3.googleusercontent.com/pw/AJFCJaX6lfi4mBg03qmXQTrfiAX6ewgPN4Y1-qLA5nfE14PzcjcHxEECKlXc08T_cpF42iQdZ585xn5sBgKdFSZfB5F7RC1aSaIFjqdDSo4VCqweeTkTERGPUSloieuddd-yEi56jSCPe4biZUMThb8gfjCB=w1920-h864-s-no?authuser=0"
          />

          <div className="text">
            {" "}
            <a
              href="https://photos.app.goo.gl/HNzx8RWjhDakPf416"
              className="galleryImage"
              rel="noreferrer"
              target="_blank"
            >
              Open Source Day
            </a>
          </div>
        </Card>

        {/* <Card style={{ width: '18rem' }} className="cardCustom">
                       
                       <Card.Img variant="top" className="img-gallery" src="https://lh3.googleusercontent.com/pw/AJFCJaWgpQXfSXwUqSly9P8X0BsyLVscuKGctFuB9GAAJaevFkPT15HUJXOSfNjFbg15Ug-eI4txHTpbSa7Pz3NYL9ebKSh1veAGzxZwaRuAPX6b-q4WxGg50bp-xrwHg8gU9UN8_ms98SyXtWAlufFUzn-p=w1264-h948-s-no?authuser=0" />
                       
                       <div className="text"> <a href="" className="galleryImage" target="_blank">Metamorphosis</a></div>
               </Card>


                <Card style={{ width: '18rem' }} className="cardCustom">
                       
                       <Card.Img variant="top" className="img-gallery" src="https://lh3.googleusercontent.com/pw/AJFCJaWgpQXfSXwUqSly9P8X0BsyLVscuKGctFuB9GAAJaevFkPT15HUJXOSfNjFbg15Ug-eI4txHTpbSa7Pz3NYL9ebKSh1veAGzxZwaRuAPX6b-q4WxGg50bp-xrwHg8gU9UN8_ms98SyXtWAlufFUzn-p=w1264-h948-s-no?authuser=0" />
                       
                       <div className="text"> <a href="" className="galleryImage" target="_blank">Metamorphosis</a></div>
               </Card>


                <Card style={{ width: '18rem' }} className="cardCustom">
                       
                       <Card.Img variant="top" className="img-gallery" src="https://lh3.googleusercontent.com/pw/AJFCJaWgpQXfSXwUqSly9P8X0BsyLVscuKGctFuB9GAAJaevFkPT15HUJXOSfNjFbg15Ug-eI4txHTpbSa7Pz3NYL9ebKSh1veAGzxZwaRuAPX6b-q4WxGg50bp-xrwHg8gU9UN8_ms98SyXtWAlufFUzn-p=w1264-h948-s-no?authuser=0" />
                       
                       <div className="text"> <a href="" className="galleryImage" target="_blank">Metamorphosis</a></div>
               </Card> */}
      </div>
    </>
  );
}

export default Gallery;
