import React from "react";
import Activities from "./Activities";
import "./Home.css";
import Inspiration from "./Inspiration";
import Contact from "./Contact";
import About from "./About";
// import Head from "./Head";

const Home = () => {
  return (
    <div className="main-homepage">
      {/* <Head /> */}
      <About />
      <Activities />
      <Inspiration />
      <Contact />
    </div>
  );
};

export default Home;
