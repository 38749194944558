import React from "react";
import classes from "./Feedback.module.css";

const Feedback = () => {
  return (
    <div className={classes.Feedback}>
      <div className={classes.Feedback_First}>
        <h1 className={classes.Feedback_Text}>
          <span className={classes.Feedback_backticks}>`</span>
          The road to{" "}
          <span className={classes.Feedback_Whiten}>improvement</span> starts
          from the <br></br>
          <span className={classes.Feedback_backticks}>
            $&#123;<span className={classes.Feedback_Whiten}>feedback</span>
            &#125;
          </span>
          <span className={classes.Feedback_backticks}>`</span>
        </h1>
      </div>
      <div className={classes.Feedback_Second}>
        <div className={classes.Feedback_Content}>
          <p>
            We really appreciate every single person who contributes to this
            journey of improvement
          </p>
          <a
            href="https://forms.gle/rYUkjy2Ny3tzBcfR9"
            target="_blank"
            rel="noreferrer"
          >
            <button>Give Feedback</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
