import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { BsGithub } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import "./Footer.css";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const history = useNavigate();
  return (
    <div className="footer">
      <div className="Container">
        <div className="List">
          <h1>WLUG</h1>
          <p style={{ color: "white" }}>Community | Knowledge | Share</p>
          <div className="Icons">
            <div>
              <a
                href="https://www.linkedin.com/company/wlug-club/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn className="social" />
              </a>
            </div>
            <div>
              <a
                href="https://mobile.twitter.com/wcewlug"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter className="social" />
              </a>
            </div>
            <div>
              <a
                href="https://github.com/Walchand-Linux-Users-Group"
                target="_blank"
                rel="noreferrer"
              >
                <BsGithub className="social" />
              </a>
            </div>
            <div>
              <a
                href="https://instagram.com/wcewlug?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="social" />
              </a>
            </div>
          </div>
        </div>
        <div className="List">
          <div>
            <h6>Location</h6>
            <p>
              Walchand College Of Engineering, Vishrambag, Sangli, Maharashtra -
              416 415
            </p>
          </div>
          <div>
            <h6>Phone</h6>
            <p>+91 8669793030</p>
          </div>
          <div>
            <h6>Email</h6>
            <a
              href="mailto:wce.wlugclub@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              wce.wlugclub@gmail.com
            </a>
          </div>
        </div>
        <div className="List">
          <h6>Menu</h6>
          <div className="MenuPages">
            <NavLink to="/events">Events</NavLink>
            <NavLink to="/members/mentor">Member</NavLink>
            <NavLink to="/contact-us">Contact</NavLink>
          </div>
        </div>
        <div className="List">
          <h6>Join our growing discord community!</h6>
          <p>Join community of growing Linux enthusiasts</p>
          <form className="form" action="/">
            {/* <input type="text" className="discordInp" /> */}
            <a
              href="https://discord.gg/3ce8hBZfc8"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              <BsDiscord />
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Footer;
