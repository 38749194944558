// import React, { useState } from 'react'
import "./MemberNav.css";
// import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

function MemberNav() {
  const boardRoutes = [
    {
      name: "Mentor Board",
      path: "/members/mentor",
    },
    {
      name: "Main Board",
      path: "/members/main",
    },
    {
      name: "Assistant Board",
      path: "/members/assistant",
    },
    {
      name: "Member Board",
      path: "/members/member",
    },
  ];
  return (
    <>
      <div className="memberNav">
        {boardRoutes.map((routess) => (
          <Link
            to={routess.path}
            key={routess.name}
            className="nav_elements"
          >
            <div>{routess.name}</div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default MemberNav;
