import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";
import "./Layout.css";

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <Sidebar dropVal={show} setShow={setShow} />
      <div className="MainContainer">
        {show ? (
          <div
            className="Backdrop"
            onClick={() => {
              setShow(false);
            }}
          ></div>
        ) : (
          ""
        )}
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
