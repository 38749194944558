import React from "react";
import classes from "./ContactUs.module.css";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaAddressBook } from "react-icons/fa";

const ContactUs = () => {
  return (
    <div className={classes.ContactUs}>
      <div className={classes.ContactUs_Heading}>
        <h1>CONTACT US</h1>
      </div>
      <div className={classes.ContactUs_content}>
        <div className={classes.ContactUs_Single}>
          <AiOutlineMail />
          <a
            href="mailto:wce.wlugclub@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            wce.wlugclub@gmail.com
          </a>
        </div>
        <div className={classes.ContactUs_Single}>
          <BsFillTelephoneFill />
          <p>+91 8669793030</p>
        </div>
        <div className={classes.ContactUs_Single}>
          <FaAddressBook className={classes.ContactUs_Address} />
          <p>
            Walchand College Of Engineering, Vishrambag, Sangli, Maharashtra -
            416 415
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
