import React from "react";
// import { useState, useEffect } from "react";
// import ScrollContainer from 'react-indiana-drag-scroll';
import Loader from "../Loader";

function Blogspage({ soloBlog, sideBlog ,isLoading}) {
  // console.log("Right Blog:",sideBlog);
  return (
    

    <>
    <div className="container-fluid">
      <div className="blogs-heading">
        <p className="blogs-head headingBlogs" id="headings">
          THE BLOGS 
        </p>
      </div>
      <div className="blogs-main">
        {soloBlog.map((curr) => {
          return (
            <div className="solo_blog" key={curr._id}>
              <div className="card trend_card">
                <div className="card-body">
                  <img
                    src={curr !== undefined ? curr.image : ""}
                    className="card-img-top solo_blog_image"
                    alt=".."
                  />
                  <br />
                  <h5 className="card-title">
                    {curr !== undefined ? curr.title : " Title"}
                  </h5>

                  <div className="trendingCardBody">
                    <p className="card-text">
                      {curr !== undefined ? curr.content : " Title"}
                    </p>
                  </div>

                  <a
                    href={curr.redirectLink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary card-btn"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          );
        })}
        {/* Right Blog Starts */}
        <div className="right-blogs">
          <div className="slider">
            {sideBlog.map((curr) => {
              return (
                <div className="card blog_card" key={curr._id}>
                  <div className="card-body">
                    <img
                      src={
                        curr !== undefined
                          ? curr.image
                          : "https://p4.wallpaperbetter.com/wallpaper/500/615/740/technology-linux-penguin-wallpaper-preview.jpg"
                      }
                      className="card-img-top solo_blog_image"
                      alt=".."
                    />
                    <br />
                    <h5 className="card-title">
                      {curr !== undefined ? curr.title : ""}
                    </h5>

                    <div className="cardDescription">
                      <p className="card-text">
                        {curr !== undefined ? curr.content : " Title"}
                      </p>
                    </div>

                    <a
                      href={curr.redirectLink}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary card-btn"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Right Blog Ends  */}
      </div>
    </div>
    </>
  );
}

export default Blogspage;
