import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./Components/Login/Login";
import Home from "./Components/HomePage/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import Blogs from "./Components/Blogs/Blogs";
import Board from "./Components/Board/Board.js";
import Layout from "./Components/Layout/Layout";
import React from "react";
import Events from "./Components/Events/Events";
import Detail from "./Components/DetailsPage/Detail";
import Clubservice from "./Components/ClubService/Clubservice";
import Feedback from "./Components/FeedBack/Feedback";
import Gallery from "./Components/Gallery/Gallery";
import ContactUs from "./Components/ContactUs/ContactUs";
 

const title = React.createContext();
const sub_title = React.createContext();

function App() {
  return (
    <div className="App">
      {/* <WavyContainer/> */}
      <Layout>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/club-service" element={<Clubservice />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path='/Detail/:type/:id' element={<Detail/>} />
          <Route exact path='/feedback' element={<Feedback/>} />
          <Route exact path='/contact-us' element={<ContactUs/>} />
          <Route
            exact
            path="/members/mentor"
            element={
              <title.Provider value={"Mentor Board"}>
                <sub_title.Provider value={"Hello this is our Mentor board"}>
                  <Board />
                </sub_title.Provider>
              </title.Provider>
            }
          />
          <Route
            exact
            path="/members/main"
            element={
              <title.Provider value={"Main Board"}>
                <sub_title.Provider value={"Hello this is our Main board"}>
                  <Board />
                </sub_title.Provider>
              </title.Provider>
            }
          />
          <Route
            exact
            path="/members/assistant"
            element={
              <title.Provider value={"Assistant Board"}>
                <sub_title.Provider value={"Hello this is our Assistant board"}>
                  <Board />
                </sub_title.Provider>
              </title.Provider>
            }
          />
          <Route
            exact
            path="/members/member"
            element={
              <title.Provider value={"Member Board"}>
                <sub_title.Provider value={"Hello this is our Member board"}>
                  <Board />
                </sub_title.Provider>
              </title.Provider>
            }
          />
        </Routes>
        <Routes>
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
export { title, sub_title };
