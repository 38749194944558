import React from 'react';
import { Link } from "react-router-dom";

function Recentservices({pastEvents}) {
  return (
    <div className="container-fluid">
      <div className="trending-posts-heading">
        <p className="trending-posts" id="headings">Recent Club Services</p>
      </div>
      <div className="trending">
        {
          pastEvents.map((curr) => {
            return (
              <div className="card trend_card" key={curr._id}>
                <img
                src={curr !== undefined ? curr.Image:"https://p4.wallpaperbetter.com/wallpaper/500/615/740/technology-linux-penguin-wallpaper-preview.jpg"}
                className="card-img-top solo_blog_image"
                alt=".."
              />
                <div className="card-body">
                  <h5 className="card-title">{curr !== undefined ? curr.title : ""}</h5>
                  <div className="trendingCardBody">
                    <p className="card-text ">
                    {curr !== undefined ? curr.Detail:" Title"}
                    </p>
                  </div>
                  <Link to={`/Detail/Clubservices/${curr._id}`} className="btn btn-primary card-btn">Read More</Link>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  )
}

export default Recentservices;
