import React from "react";
import "../Blogs/Blogs";
import { Link } from "react-router-dom";

export default function Eventspage({ soloEvent, sideEvent }) {
  return (
    <div className="container-fluid">
      <div className="blogs-heading">
        <p className="blogs-head headingBlogs" id="headings">
          EVENTS
        </p>
      </div>
      <div className="blogs-main">
      {soloEvent.map((curr) => {
          return (
            <div className="solo_blog" key={curr._id}>
              <div className="card trend_card">
                <div className="card-body">
                  <img
                    src={curr !== undefined ? curr.photo : ""}
                    className="card-img-top solo_blog_image"
                    alt=".."
                  />
                  <br />
                  <h5 className="card-title">
                    {curr !== undefined ? curr.name : " Title"}
                  </h5>

                  <div className="trendingCardBody">
                    <p className="card-text">
                      {curr !== undefined ? curr.detail : " Title"}
                    </p>
                  </div>
                  <Link
                      to={`/Detail/Event/${curr._id}`}
                      className="btn btn-primary card-btn"
                    >
                      Read More
                    </Link>
                </div>
              </div>
            </div>
          );
        })}
        {/* Right Blog Starts */}
        <div className="right-blogs">
          <div className="slider">
            {sideEvent.map((curr) => {
              return (
                <div className="card blog_card" key={curr._id}>
                  <div className="card-body">
                    <img
                      src={
                        curr !== undefined
                          ? curr.photo
                          : "https://p4.wallpaperbetter.com/wallpaper/500/615/740/technology-linux-penguin-wallpaper-preview.jpg"
                      }
                      className="card-img-top solo_blog_image"
                      alt=".."
                    />
                    <br />
                    <h5 className="card-title">
                      {curr !== undefined ? curr.name : ""}
                    </h5>

                    <div className="cardDescription">
                      <p className="card-text">
                        {curr !== undefined ? curr.detail : " Title"}
                      </p>
                    </div>

                    <Link
                      to={`/Detail/Event/${curr._id}`}
                      className="btn btn-primary card-btn"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
