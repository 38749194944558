// import React, { useRef, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import "./Blog.css";
function RecentBlogs({ recentBlog }) {
  return (
    <div className="container-fluid">
      <hr className="purp-hr" />
      <div className="recent-blogs-heading">
        <p className="recent-blogs" id="headings">
          RECENT BLOGS
        </p>
      </div>
      <ScrollContainer
        horizontal="true"
        className="wrapper-container-recent-blogs container-fluid rowCSS gridGap"
      >
        {recentBlog.map((curr) => {
          return (
            <div className="card blog_card" key={curr._id}>
              <div className="card-body Recentcard">
                <div className="SectionFirst">
                <img
                  src={
                    curr !== undefined
                      ? curr.image
                      : "https://p4.wallpaperbetter.com/wallpaper/500/615/740/technology-linux-penguin-wallpaper-preview.jpg"
                  }
                  className="card-img-top solo_blog_image"
                  alt=".."
                />
                <h5 className="card-title head">
                  {curr !== undefined ? curr.title : ""}
                </h5>
                <div className="cardDescription">
                  <p className="card-text">
                    {curr !== undefined ? curr.content : ""}
                  </p>
                </div>
                </div>
                <a
                  href={curr.redirectLink}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary card-btn"
                >
                  Read More
                </a>
              </div>
            </div>
          );
        })}
      </ScrollContainer>
    </div>
  );
}

export default RecentBlogs;
