import React from 'react';
import styles from './Board.module.css'
import BoardList from './BoardList/BoardList';
import MemberNav from './MemberNav';
import {title,sub_title} from '../../App'
import { useContext } from 'react';


const Board = () =>
{
    const title1  = useContext(title)
    const sub_title1 = useContext(sub_title);
    
    return(
        <>
        <MemberNav/>
        {/* <div className='container d-flex justify-content-center align-items-center'>
            <div className='child_container d-flex
            flex-column align-items-start justify-content-center text-white'>
                <div className='title'>
                    Meet the {title1}
                </div>
                <div className='sub_title'>
                    {sub_title1}
                </div>
                <div className='search'>
                    <input className='Inputele' type='text' placeholder='Search by place or name'/>
                </div>
            </div>
        </div> */}

        <BoardList/>    
        </>
    )
}

export default Board;
// export {title1,sub_title1}
