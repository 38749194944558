import React from "react";
import classes from "./Card.module.css";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineMail, AiOutlineLinkedin } from "react-icons/ai";

const Card = ({
  name,
  image,
  quote,
  areaOfInterest,
  role,
  board,
  mail,
  insta,
  linkedin,
}) => {
  return (
    <div className={`${classes.card_container}`}>
      <div className={classes.Card_Circle}></div>
      <div className={classes.Card_Image}>
        <div className={classes.Card_ImageContainer}>
          <div className={classes.Card_InnerImgeContainer}>
            <img src={image} alt="profile" />
          </div>
        </div>
        <p className={classes.Card_name}>{name}</p>
        <p className={classes.Card_Board}>{role}</p>
      </div>
      <div className={classes.Card_Detail}>
        <p className={classes.Card_quote}>"{quote}"</p>
        <p className={classes.Card_Interest}>{areaOfInterest}</p>
      </div>
      <div className={classes.Card_Social}>
        <div className={classes.Card_SocialContainer}>
          <a href={`mailto:${mail}`} target="_blank" rel="noreferrer">
            <AiOutlineMail />
          </a>
          <a href={insta} target="_blank" rel="noreferrer">
            <BsInstagram />
          </a>
          <a href={linkedin} target="_blank" rel="noreferrer">
            <AiOutlineLinkedin />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
