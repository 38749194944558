import React,{useState,useEffect} from 'react'
import Clubservices from './Clubservices';
import Recentservices from './Recentservices';
import Pastservices from './Pastservices';
import Loader from '../Loader';

function Clubservice() {
    const [services,setClubService] = useState([]);
    const [loading,setLoading] = useState(true);

  const getData = async () => {
    const cservices = await fetch("https://backend.wcewlug.org/api/v1/clubservices/getAllCS");
    const data = await cservices.json();
    setLoading(false);
    setClubService(data);
  };

  useEffect(() => {
    getData();
  },[]);
  const sideEvent = services.theBoys !== undefined? services.theBoys.clubService.slice(4,15):[];
  console.log(services);
  const pastEvents = services.theBoys !== undefined? services.theBoys.clubService.slice(1,4):[];
  const soloEvent = services.theBoys !== undefined? [services.theBoys.clubService[0]]:[];
  const pastServices = services.theBoys !== undefined? services.theBoys.clubService.slice(15):[];
  console.log(pastServices);




  // console.log("EVENTS API:",soloEvent,sideEvent,pastEvents);
  return (
    <>
    {loading ? <Loader /> :

    <div className="container-fluid blogs_page">
    <Clubservices soloEvent={soloEvent} sideEvent={sideEvent} />
    <Recentservices pastEvents={pastEvents} />
    <Pastservices pastServices={pastServices}/>
    </div>
    
    }
    
   </>
  )
}

export default Clubservice;
